.account-nav {
	padding: 10px 0;
}

.account-nav__list {
	border-top: 5px solid $black; // TODO: Theme
}

.account-nav__link {
	display: block;
	padding: 4px 8px;
	color: $black;
}

.account-nav__link:hover {
	background-color: $gray8;
}

.account-nav__link--selected, .account-nav__link--selected:hover {
	font-weight: bold;
	color: $black;
	text-decoration: none;
	background-color: $gray7;
}

@media only screen and (max-width: 680px) {
	.account-nav__list {
		padding-bottom: 20px;
		border-top: none;
		overflow: hidden;
	}

	.account-nav__list > li {
		float: left;
		width: 33%;
		text-align: center;
	}

	.account-nav__link {
		display: inline-block;
		padding: 5px 10px;
		border-radius: 2px;
	}
}
