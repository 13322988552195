.header-banner {
	max-width: 1000px;
	margin: 0 auto;
	padding: 10px;
}

.header-banner__buttons {	
	margin: 5px -5px 0;
	overflow: hidden;
}

.header-banner__button {
	float: left;
	width: 50%;
	padding: 0 5px;
}

.header-banner .social {
	float: right;
}

@media only screen and (min-width: 540px) {
	.header-banner {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
	}

	.header-banner__section--left {
		flex: 1 1 auto;
		align-self: center;
		min-height: 1px;
	}

	.header-banner__section--right {
		flex: 0 0 270px;
		align-self: flex-end;
		margin-left: auto;
		padding-right: 5px;
	}
}

@media only screen and (min-width: 1000px) {
	.header-banner {
		padding: 10px 0;
	}
}