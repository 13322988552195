// --- REMIX
.products__item {
	display: flex;
}

.product {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 30px 15px;
	text-align: center;
	border: 1px solid transparent;
	border-radius: 2px;
	transition: border-color 0.3s;
	margin: 3px 5px;

	&:hover {
		border-color: #eee;
	}

	&.product--is-promo:hover {
		border-color: #ccc;
	}
}

.product__link {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	color: inherit;

	&:hover {
		text-decoration: none;

		.product__name {
			text-decoration: underline;
		}
	}
}

.product__photo {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 0 auto;
}

.product__text {
	flex: 1 0 auto;
}

.product__name {
	margin: 1em 0 0;
	padding: 0;
	font-size: 16px;
	font-weight: normal;
	line-height: 1.2;

	.product__name-brand {
		font-weight: bolder;
	}
}

.product__detail {
	margin: 5px 0;
	padding: 0;
	font-size: 12px;
}

.product__price {
	margin-top: 15px;
}

.product__quickview {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.4s;

	.product:hover & {
		opacity: 1;
		pointer-events: auto;
	}
}

@media only screen and (min-width: 460px) {
	.products__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.products__item {
		width: 50%;
	}

	.promo-featured-product .products__item {
		width: calc(100% / 3);
	}
}

@media only screen and (min-width: 840px) {
	.products__item {
		width: calc(100% / 3);
	}

	.promo-featured-product .products__item,
	.best-sellers-product .products__item {
		width: 25%;
	}
}

// bug fixes (IE<11)
.product__image, .product__link {
	min-height: 1px;
}

@media only screen and (min-width: 820px) {
	.ui-quickview .prod-info, .has--pencil .ui-quickview .prod-info {
		padding-top: 0;
	}

	.ui-quickview .product-img, .has--pencil .ui-quickview .product-img {
		margin-top: 0;
	}
}