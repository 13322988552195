.message--added {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	color: $white;
	font-size: 16px;
	font-weight: bold;
	background: rgb(51, 131, 38);
	background: rgba(51, 131, 38, 0.8);
}

.message--added > span {
	display: block;
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	margin: 0 auto;
	padding: 0 10%;
	text-align: center;
	transform: translateY(-50%);
}
