.login {
	padding: 30px 0 0;
}

.login-section {
	padding: 25px 15px;
	text-align: center;
	width: 100%;
}

.login-section--login {
	padding: 15px 15px;
}

.login-section--register,
.login-section--apply {
	border-top: 1px solid #333333;
}

.login-section__header {
	font-size: 2em;
	font-weight: bold;
}

.login-section__description {
	line-height: 1.7;
	font-size: 18px;
}

.login-section__actions {
	margin-top: 20px;

	& .button {
		margin-right: 10px;
		margin-left: 10px;
	}
}

.login-resp {
	font-weight: bold;
}

.login-form {
	padding-bottom: 10px;
}

@media only screen and (min-width: 720px) {
	.login {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.login-section {
		width: 33%;
		padding: 15px 30px;
	}

	.login-section--login {
		padding: 15px 30px;
	}

	.login-section--register,
	.login-section--apply {
		border-top: none;
	}
}

.forgot-login-panels {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	p {align-self: center;}

	.forgot-panel-container {
		margin: 10px 6px;
		padding: 20px;
		height: 230px;
	}

	.forgot-panel--login, .forgot-panel--password {
		background: transparent;
		border: 1px solid #eee;
	}

	.forgot-input {
		display: grid;
		grid-template-columns: 1fr auto auto;
		align-items: center;
		grid-gap: 6px;
	}
}

@media (max-width: 480px) {
	.forgot-login-panels {
		.forgot-panel-container {
			padding: 20px 8px;
		}
		.forgot-password-input--container {
			div {
				margin: 6px 0;
			}
		}
	}
}



@media only screen and (min-width: 1000px) {
	.login-form__input input {
		min-width: 250px;
	}
}

.login-form__row {
	margin-bottom: 10px;
	padding: 5px 0;
}

.login-form__label {
	display: block;
	padding: 5px 5px 0 0;
}

.login-form__input {
	width: 100%;
	padding: 5px 0;
}

.login-form__field {
	width: 100%;
	max-width: 150px;
	padding: 8px;
	border: 1px solid #c0c0c0;
	border-radius: 2px;
}

.login-form__actions {
	padding: 15px 0;
}

.login-form__forgot {
	font-size: 11px;
}

.not-found {
	background-color: #f6d4d4;
	border: 2px solid #bd6666;
	border-radius: 2px;
	padding: 5px;
}

.not-found-header {
	margin: 0;
	padding: 15px 10px 0;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
}

.not-found-text {
	font-size: 16px;
	text-align: center;
}

.register-found {
	text-align: left;
	vertical-align: top;
}

.register-found-form {
	background-color: #d2ffca;
	border: 2px solid #549549;
	border-radius: 2px;
}

.found-header {
	margin: 0;
	padding: 15px 10px 0;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
}

.register-found h5 {
	margin: 0;
	padding: 0 0 0.5em;
	font-size: 1.15em;
}

.register-found__requirements {
	margin: 5px 0 15px;
}

.register-found .faux-table {
	margin: 15px 0 0;
}

.found-inner {
	padding: 10px 10px 0;
}

.found-continue {
	padding: 10px 0 0;
}

// --- Quick Connect
.quick-connect__header {
	margin: 30px 0;
}

.quick-connect__form {
	max-width: 300px;
}

.quick-connect__image--desktop {
	display: none;
}

@media only screen and (min-width: 768px) {
	.quick-connect {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
	}

	.quick-connect__header {
		flex: 1;
		order: 2;
		margin-top: 0;
		padding-left: 30px;
	}

	.quick-connect__image--mobile {
		display: none;
	}

	.quick-connect__image--desktop {
		display: block;
	}
}

// --- Forgot Username/Password
.modal--forgot {
	.ui-dialog-titlebar {
		margin: 0 10px;
		padding: 10px 5px;
		font-size: 16px;
		background: none;
		border: none;
		border-radius: 0;
		border-bottom: 1px solid #ddd;
	}

	.ui-dialog-titlebar-close {
		background: none;
		border: none;
	}

	.ui-dialog-content {
		line-height: 1.7;
	}
}

.forgot-form {
	.button {
		margin-top: 10px;
	}
}
