.breadcrumb {
	padding: 7px 10px;
	font-size: 0.9em;
	text-transform: uppercase;

	//max width on all edu pages
	.page--education-default & {
		max-width: 1000px;
		margin: 0 auto;
	}

	//Globally hide breadcrumbs on login pages
	.page--login-default & {
		display: none;
	}
}

.breadcrumb__help {
	float: right;
}

.breadcrumb__links {
	float: left;
}

.breadcrumb__link {
	color: $gray1;
	text-decoration: none;

	&:hover {
		text-decoration: none;

		span {
			text-decoration: underline;
		}
	}

	&::before {
		content: ' / ';
	}
}

.breadcrumb__link:first-child::before {
	content: '';
}

@media only screen and (max-width: 420px) {
	.breadcrumb__help {
		clear: both;
		float: none;
		padding: 5px 0 0;
		font-style: italic;
		text-transform: none;
	}	
}
