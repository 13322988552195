.slider--featured .bx-viewport {
	.slidewrap {
		overflow-y: visible;
	}

	.slidewrap a {
		display: block;
		text-align: center;
		text-decoration: none;

		&::after {
			content: 'Shop Now';
			position: relative;
			top: -0.5em;
			display: inline-block;
			margin: 0 auto;
			padding: 0.5em 1em;
			font-size: 12px;
			font-style: normal;
			font-weight: bold;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			opacity: 0;
			background-color: #fff;
			border: 2px solid #000;
			transition: opacity 0.4s, transform 0.4s;
			transform: translateY(0);
			white-space: nowrap;
		}

		.slider-caption {
			transition: opacity 0.3s;
		}
	}

	.slidewrap a:hover {
		&::after {
			opacity: 1;
			text-decoration: none;
			transform: translateY(-0.5em);
		}

		.slider-caption {
			opacity: 0;
			text-decoration: none;
		}
	}
}

.slider--featured .bx-wrapper {
	margin-bottom: 15px;
}

@media only screen and (min-width: 480px) {
	.slider--featured .bx-viewport {
		.slidewrap a::after {
			font-size: 18px;
		}
	}
}

// .home-article .article--animation,
// .slidewrap a {
//   &:after {
//     content: "SHOP NOW";
//     display: inline-block;
//     position: relative;
//     top: 0;
//     left: 50%;
//     opacity: 0;
//     background: #fff;
//     padding: 10px 21px;
//     transition: top 0.5s, opacity 0.5s;
//     border: 2px solid;
//     font-family: basic-sans, sans-serif;
//     font-weight: 600;
//     font-style: normal;
//     text-decoration: none;
//     font-size: 24px;
//     transform: translateX(-50%);
//   }
// }

// .slidewrap a {
// 	.slider-caption {
// 		opacity: 1;
// 		transition: opacity 0.5s;
// 	}

// 	&:hover {
// 		text-decoration: none;

// 		&:after {
// 			top: -13px;
// 			opacity: 1;
// 		}

// 		.slider-caption {
// 			opacity: 0 !important;
// 		}
// 	}
// }