@keyframes circle {
	0%, 100% {
		box-shadow: 0em -2em 0em 0em white,
					1.4em -1.4em 0em 0em rgba(white, 0.2),
					2em 0em 0em 0em rgba(white, 0.2),
					1.4em 1.4em 0em 0em rgba(white, 0.2),
					0em 2em 0em 0em rgba(white, 0.2),
					-1.4em 1.4em 0em 0em rgba(white, 0.4),
					-2em 0em 0em 0em rgba(white, 0.6),
					-1.4em -1.4em 0em 0em rgba(white, 0.8);
	}

	12.5% {
		box-shadow: 0em -2em 0em 0em rgba(white, 0.8),
					1.4em -1.4em 0em 0em white,
					2em 0em 0em 0em rgba(white, 0.2),
					1.4em 1.4em 0em 0em rgba(white, 0.2),
					0em 2em 0em 0em rgba(white, 0.2),
					-1.4em 1.4em 0em 0em rgba(white, 0.2),
					-2em 0em 0em 0em rgba(white, 0.4),
					-1.4em -1.4em 0em 0em rgba(white, 0.6);
	}

	25% {
		box-shadow: 0em -2em 0em 0em rgba(white, 0.6),
					1.4em -1.4em 0em 0em rgba(white, 0.8),
					2em 0em 0em 0em white,
					1.4em 1.4em 0em 0em rgba(white, 0.2),
					0em 2em 0em 0em rgba(white, 0.2),
					-1.4em 1.4em 0em 0em rgba(white, 0.2),
					-2em 0em 0em 0em rgba(white, 0.2),
					-1.4em -1.4em 0em 0em rgba(white, 0.4);
	}

	37.5% {
		box-shadow: 0em -2em 0em 0em rgba(white, 0.4),
					1.4em -1.4em 0em 0em rgba(white, 0.6),
					2em 0em 0em 0em rgba(white, 0.8),
					1.4em 1.4em 0em 0em white,
					0em 2em 0em 0em rgba(white, 0.2),
					-1.4em 1.4em 0em 0em rgba(white, 0.2),
					-2em 0em 0em 0em rgba(white, 0.2),
					-1.4em -1.4em 0em 0em rgba(white, 0.2);
	}

	50% {
		box-shadow: 0em -2em 0em 0em rgba(white, 0.2),
					1.4em -1.4em 0em 0em rgba(white, 0.4),
					2em 0em 0em 0em rgba(white, 0.6),
					1.4em 1.4em 0em 0em rgba(white, 0.8),
					0em 2em 0em 0em white,
					-1.4em 1.4em 0em 0em rgba(white, 0.2),
					-2em 0em 0em 0em rgba(white, 0.2),
					-1.4em -1.4em 0em 0em rgba(white, 0.2);
	}

	62.5% {
		box-shadow: 0em -2em 0em 0em rgba(white, 0.2),
					1.4em -1.4em 0em 0em rgba(white, 0.2),
					2em 0em 0em 0em rgba(white, 0.4),
					1.4em 1.4em 0em 0em rgba(white, 0.6),
					0em 2em 0em 0em rgba(white, 0.8),
					-1.4em 1.4em 0em 0em white,
					-2em 0em 0em 0em rgba(white, 0.2),
					-1.4em -1.4em 0em 0em rgba(white, 0.2);
	}

	75% {
		box-shadow: 0em -2em 0em 0em rgba(white, 0.2),
					1.4em -1.4em 0em 0em rgba(white, 0.2),
					2em 0em 0em 0em rgba(white, 0.2),
					1.4em 1.4em 0em 0em rgba(white, 0.4),
					0em 2em 0em 0em rgba(white, 0.6),
					-1.4em 1.4em 0em 0em rgba(white, 0.8),
					-2em 0em 0em 0em white,
					-1.4em -1.4em 0em 0em rgba(white, 0.2);
	}

	87.5% {
		box-shadow: 0em -2em 0em 0em rgba(white, 0.2),
					1.4em -1.4em 0em 0em rgba(white, 0.2),
					2em 0em 0em 0em rgba(white, 0.2),
					1.4em 1.4em 0em 0em rgba(white, 0.2),
					0em 2em 0em 0em rgba(white, 0.4),
					-1.4em 1.4em 0em 0em rgba(white, 0.6),
					-2em 0em 0em 0em rgba(white, 0.8),
					-1.4em -1.4em 0em 0em white;
	}
}
