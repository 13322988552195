.credit-cards {
}

.credit-card {
	display: inline-block;
	width: 73px;
	height: 44px;
	background-image: url('/images/credit-cards.png');
	background-repeat: no-repeat;
	background-position: 0 0;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	transition: opacity 0.4s;
}

.credit-card--visa {
	background-position: 0 0;
}

.credit-card--mastercard {
	background-position: -73px 0;
}

.credit-card--discover {
	background-position: -146px 0;
}

.credit-card--amex {
	background-position: -219px 0;
}

.credit-cards--highlighted .credit-card {
	opacity: 0.4;
}

.credit-cards--highlighted .credit-card--highlight {
	opacity: 1;
}

.rightsign-link {
	margin: 0;
	padding-top: 0;
	padding-bottom: 30px;
}
