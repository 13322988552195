.store-popup {
	z-index: 1000;
}

.store-popup__overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	background-color: rgba(255, 255, 255, 0.6);
}

.store-popup__box {
	position: fixed;
	top: 50%;
	right: 0;
	left: 0;
	z-index: 1100;
	width: 75%;
	max-width: 500px;
	margin: 0 auto;
	padding: 0;
	color: #4c4c4c;
	background-color: #fff;
	border: 2px solid black;
	transform: translateY(-50%);

	.store-popup__title {
		margin: 0 0 5px;
		padding: 0;
		color: #fff;
		font-size: 24px;
		text-transform: uppercase;
	}
}

.store-popup__header {
	position: relative;
}

.store-popup__close {
	position: absolute;
	top: 50%;
	right: 0;
	margin-right: 5px;
	padding: 10px;
	color: #fff;
	font-size: 20px;
	font-weight: bold;
	line-height: 1;
	text-transform: uppercase;
	background-color: transparent;
	border: none;
	transform: translateY(-50%);
	cursor: pointer;
}

.store-popup__page {
	min-height: 115px;
	max-height: 400px;
	overflow-y: auto;
}

.store-popup__page--confirm {
	max-height: 450px;
	font-size: 0.9em;
}

.pickup-store {
	padding: 20px;	
	border-top: 1px solid #eee;

	&:first-child {
		border-top: none;
	}

	.pickup-store__name {
		margin: 0 0 10px;
		padding: 0;		
		font-size: 18px;
		font-weight: bold;
	}
}

.pickup-store__address {
	margin: 10px 0;
	padding: 0;
	list-style: none;
}

.pickup-store__phone {
	margin: 5px 0;
}

.pickup-store__hours {
	margin: 5px 0;
}

.pickup-store__set {
	display: inline-block;
	margin-top: 10px;
	padding: 5px 25px;
	color: #4c4c4c;
	font-size: 11px;
	background-color: transparent;
	border: 1px solid;
	border-radius: 5px;
	cursor: pointer;
	transition: color 0.2s;

	&::hover {
		color: #000;
	}
}

.pickup-store {
	.icon--phone, .icon--clock {
		margin-right: 3px;
		vertical-align: top;
	}
}

.icon--store {
	display: inline-block;
	width: 24px;
	height: 24px;
	vertical-align: middle;
	background-image: url('/images/store-white.svg');
	background-repeat: no-repeat;
	background-size: 100%;
	opacity: 0.9;
}

.shop-location__message {
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
	column-gap: 5px;
}

.shop-location__icon {
	grid-column: 1;
}

.shop-location__content {
	grid-column: 2;
	color: #fff;
}

.shop-location__text {
	display: inline;
	padding: 0;
	color: inherit;
	font-family: inherit;
	font-size: 1em;
	line-height: inherit;
	text-decoration: underline;
	background-color: transparent;
	border: none;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}
}

// --- pickup stuff
.store-popup__delivery {
	font-size: 0.9em;
}

.store-popup__inner {
	padding: 15px;
}

.delivery-subhead {
	margin: 0 0 0.5em;
	font-size: 1.15em;
}

.delivery-description {
	margin: 0 0 1.25em;
	padding: 0;
}

.delivery-form {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 15px;
}

.delivery-form__action {
	grid-column: span 4;
	padding: 10px 0 5px;
}

.delivery-form__field {
	padding: 5px 0;
}

.delivery-form__field--half {
	grid-column: span 2;
}

.delivery-form__field--full {
	grid-column: span 4;
}

.delivery-field {
	width: 100%;
	padding: 5px 10px;
	border: 1px solid #ccc;
	border-radius: 2px;
}

.delivery-or {
	position: relative;
	margin: 5px 45px;
	text-align: center;

	span {
		position: relative;
		top: -1px;
		z-index: 80;
		display: inline-block;
		padding: 5px 25px;
		background-color: #fff;
		font-style: italic;
	}

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		display: block;
		height: 1px;
		background-color: #ddd;
	}
}

.store-popup__page[aria-hidden="false"] {
	display: block;
}

.store-popup__page[aria-hidden="true"] {
	display: none;
}

.store-popup__header {
	padding: 0 20px 0 0;
	background-color: #fff;
	border-bottom: 5px solid black;
}

.store-popup__header--confirm {
	padding: 5px 20px 0 10px;
	background-color: black;

	.store-popup__close {
		color: #fff;
	}
}

.store-popup-tabs__switch {
	display: inline-block;
	margin: 0 5px 0 0;
	padding: 8px 10px;
	font-family: inherit;
	font-size: 1em;
	line-height: 1;
	background-color: #fff;
	border: none;
	cursor: pointer;
}

.store-popup-tabs__switch--active {
	color: #fff;
	background-color: black;
}

.store-popup__close {
	margin-top: -0.1em;
	color: black;
	background-color: transparent;
}

.delivery-options, .delivery-results {
	&[aria-hidden="true"] {
		display: none;
	}

	&[aria-hidden="false"] {
		display: block;
	}
}

.delivery-results__header {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.store-popup__new-address {
	display: inline-block;
	margin-left: auto;
	padding: 0;
	font-family: inherit;
	font-size: 1em;
	line-height: 1;
	text-decoration: underline;
	background: none;
	border: none;
	cursor: pointer;

	&:hover {
		opacity: 0.7;
	}
}

.delivery-noresults {
	padding-top: 0;

	p {
		margin: 0 0 1.25em;
		padding: 0;
	}
}

.delivery-noresults__actions {
	text-align: center;
}

.store-popup__errors {
	&[aria-hidden="true"] {
		display: none;
	}

	&[aria-hidden="false"] {
		display: block;
	}
}

.store-popup__error {
	color: #ff4136;
}

.label-error {
	color: #ff4136;
}

.store-popup__address, .store-popup__section {
	margin: 0 0 15px;
}

.store-popup__footer {
	padding: 15px;
	border: 1px solid #eee;

	p {
		margin: 0 0 15px;
		padding: 0;
	}
}