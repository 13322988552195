﻿// --- #education-all --- //
.education-content {
    max-width: 1000px;
    margin: 0 auto;
}

.education-filters {
    padding-top: 5px;
}

.education-all {
    padding: 0 0 0 15px;
}

.education-all__header {
    margin: 0;
    padding: 5px 0 0.5em;
    font-size: 1.25em;
}

.education-all__filters {
    margin: 5px;
    padding: 5px 0;
    border-color: #ddd;
    border-style: solid;
    border-width: 1px 0;
}

.education-all__call {
    text-align: right;
}

.education-all__filters .row {
    margin-right: 0;
    margin-left: 0;
}

@media only screen and (max-width: 680px) {
    .education-all {
        margin-top: 15px;
        padding: 0 10px;
    }
}

.edu-list-item__title a {
    color: #000;
}

.edu-list-item__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    height: auto;
}

.edu-list-item__image-overlay {
    width: 100%;
}

.edu-list-item__dates {
    font-size: .9em;
}

@media only screen and (max-width: 680px) {
    .edu-list-item__title {
        margin-top: 10px;
    }
}

.education-class__flyer {
    display: flex;
    align-items: center;

    img {
        max-width: 22px;
    }
}

.is--logged-in .education-jotform {
    display: none;
}