.account-blocked {
	width: 98%;
	margin: 0 auto;
	color: $red;
	font-weight: bold;
}

.account-panel {
	padding: 0 0 20px;
}

.account-panel--error {
	padding: 0 10px 10px;
}

.account-panel--change {
	padding: 0 10px 20px;
}

.account-panel__header {
	margin: 0 0 0.5em;
	padding: 0 0 0.25em;
	font-size: 1.25em;
	font-weight: bold;
	border-bottom: 1px solid #ddd;
}

.account-panel__action {
	margin: 0;
	padding: 0 0 20px;
}

.address-new {
	font-size: 0.9em;
	font-weight: normal;
}

.account-panel .input--full {
	margin-bottom: 5px;
}

.address-wrap {
	padding: 0 20px;
}

@media only screen and (min-width: 680px) {
	.address-wrap {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-bottom: 20px;
	}

	.address-wrap .account-panel {
		width: calc(50% - 10px);
	}

	.address-wrap .account-panel--error {
		width: 100%;
	}
}
