// --- basics
.dialog-container, .dialog-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.dialog-container {
	z-index: 9001;
	display: flex;
}

.dialog-overlay {
	background-color: rgba(43, 46, 56, 0.6);
}

.dialog-content {
	position: relative;
	z-index: 2;
	align-self: center;
	margin: auto;
	background-color: #fff;
}

.dialog-container[aria-hidden="true"] {
	display: none;
}

// --- not basics
.dialog-content {
	width: 80%;
	max-width: 600px;
	max-height: 80vh;
	padding: 25px;
	font-size: 18px;
	line-height: 1.5;
	overflow-y: auto;
}

.dialog-close {
	position: absolute;
	top: -2px;
	right: -2px;
	width: 36px;
	height: 36px;
	line-height: 1;
	margin: 0;
	padding: 0;
	font-family: inherit;
	font-size: 36px;
	font-weight: bold;
	background-color: transparent;
	outline: none;
	border: none;

	&:hover {
		cursor: pointer;
	}
}

.dialog-title {
	margin-bottom: 0.5em;
	padding-right: 35px;
}

.dialog-content p:last-child {
	margin-bottom: 0;
}

// --- topic popup
.dialog-content--topic {
	max-width: 900px;
	padding: 45px 0 0 0;
	border-radius: 2px;
	max-height: auto;
	overflow-y: hidden;
}

.topic-content {
	max-height: 80vh;
	padding: 0 20px 20px;
	overflow-y: auto;
}

// --- view photo
.dialog-content--view-photo {
	max-width: 450px;

	.prod-info__name {
		font-size: 16px;
	}
}

@media only screen and (min-width: 820px) {
	.dialog-content--view-photo {
		.prod-info__name {
			font-size: 24px;
		}
	}
}

// --- add to list
.dialog-content--add-list {
	font-size: 16px;
}

// --- quick view
.dialog-content--quick-view {
	max-width: 900px;
	font-size: 14px;
}
