.dashboard__input-required {
    .popover__content {
        max-width: 350px;
        padding: 15px;
        text-align: left;

        p {
            margin: 0 0 15px 0;
            padding: 0;
        }
    }

    .lbl--error {
        display: block;
        margin-bottom: 15px;
    }
}

.single-input {
  display: flex;
  flex-direction: row;

  .input--full {
    flex: 1;
  }

  .button {
    flex: 0 auto;
    border-radius: 0;
  }
}

.dashboard-section {
    margin: 50px 5px 0;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 10px;
}

.dashboard-banners {
    .standard-banner {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .standard-banner__button {
        display: none;
    }

    .standard-banner__title {
        display: none;
    }
}

.dashboard-list {
    line-height: 1.3;
    display: flex;
    flex-wrap: wrap;
}

.dashboard__header {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    margin: 10px 0 15px;
    padding: 0 20px;

    input {
        margin-top: 10px;
    }
}

.dashboard__title {
    margin: 0;    
    grid-column: 1 / span 3;
    justify-self: center;
}

.dashboard-list__item {
    position: relative;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-gap: 10px;
    align-items: start;
    width: 100%;
    border-bottom: 1px solid #ddd;

    &:last-of-type {
        border-bottom: none;
    }
}

.dashboard__no-items {
    padding: 0 10px;

    a {
        text-decoration: underline;
    }
}

.dashboard-list__add-qty {
  text-align: center;
  grid-row: 2;
  display: flex;
  align-items: center;
  column-gap: 2px;
}

.dashboard-list__link {
  color: inherit;
}

.dashboard-list__name {
  margin: 0;
  padding: 0 10px 0 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.3;
}

.dashboard-list__size {
  font-size: 0.8em;
  white-space: nowrap;
}

.dashboard-list__sku,
.dashboard-sample__date {
  font-size: 11px;
}

.dashboard-list__price {
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  min-width: 65px;

  .price__current {
    font-size: 1.5em;
  }

  .price__label,
  .price__label--salon,
  .price__label--sale,
  .price__label--savings {
    font-size: 0.75em;
    font-weight: normal;
  }
}

.dashboard-list__stock,
.dashboard-list__contract {
    grid-row: 2;    
}

.dashboard-list__contract {
    font-weight: normal;
}

.dashboard-list__photo {
    display: block;
    max-width: 75px;
    height: auto;
    margin: 0 auto;
}

.dashboard-sample__title {
  font-weight: bold;
  text-transform: uppercase;
}

.dashboard__disclaimer {
    font-size: 0.9em;
    margin-top: 8px;
    grid-column: 1 / span 3;
    text-align: center;
}

.dashboard-list__purchase {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr auto auto;    
    justify-items: end;
    margin-top: 0;
    row-gap: 5px;
}

.dashboard-list .dashboard-list__purchase {
    align-items: center;
}

.dashboard__footer {
    padding-top: 10px;
    text-align: center;    

    .button {
        margin-bottom: 5px;
    }
}

.dashboard__add-all {
    grid-column: 2;
    justify-self: center;
}

.dashboard__error {
    display: block;
    padding-bottom: 5px;
    text-align: center;
}

.dashboard__next-link {
    display: inline-block;
    padding-right: 5px;
    margin-left: auto;

    &::after {
        content: " >";
    }
}

.dashboard .button--small {
    border-radius: 0px;
    border: none;
}

@media only screen and (min-width: 480.02px) {
    .dashboard__header {
        input {
            margin-top: 5px;
        }
    }

    .dashboard__add-all {
        grid-column: 3;
        justify-self: end;
    }

    .dashboard-list__item {
        grid-gap: 10px;
    }

    .dashboard__footer {
        text-align: right;
        padding-right: 20px;
    }
}

@media only screen and (min-width: 820px) {
    .dashboard__title {
        grid-column: 2;
    }

    .dashboard-sample-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
    }

    .dashboard-list__item {
        grid-template-columns: 75px 3fr 1fr;
        /*align-items: center;*/
        width: 50%;
        border-bottom: none;
    }

    .dashboard-list__photo {
        max-width: 100%;
    }

    .dashboard-list__purchase {
        grid-template-columns: auto auto;
        /*justify-items: start;*/
        justify-self: end;
        /*column-gap: 10px;*/
        /*margin-top: 10px;*/

        .input--qty {
            justify-self: center;
        }
    }
}

@media only screen and (min-width: 1050px) {
    .dashboard-section {
        margin: 50px 0 0;
    }
}