.landing-password {
	max-width: 350px;
	margin: 60px auto;
	padding: 30px;
	border: 1px solid #e1e1e1;
	border-radius: 3px;
	box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

.landing-password__text {
	margin-bottom: 30px;
}

.landing-password__form {
	margin-top: 20px;
}

.landing-password__error {
	margin-bottom: 15px;
}

.landing-password__label {
	display: block;
	margin-bottom: 2px;
}

.landing-password__actions {
	margin-top: 10px;
}
