.video-placeholder {
	position: relative;
	display: block;
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		padding-bottom: 56.25%;
	}

	iframe, embed, object {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.video-placeholder__image {
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: auto;
		max-width: 100%;
		margin: auto;
		border: none;
		cursor: pointer;
		transition: .4s all;
	}
}


.video-placeholder__button {
	display: block;
	font-family: inherit;
	line-height: inherit;
	background-color: transparent;
	border: none;

	&:hover .video-placeholder__image {
		filter: brightness(75%);
	}

	&:hover .play {
		background-color: rgba(0, 0, 0, 1);
	}
}

.play {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	width: 72px;
	height: 72px;
	margin: auto;
	font-family: inherit;
	font-size: 2px;
	border: none;
	border-radius: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	cursor: pointer;
	text-indent: 105%;
	overflow: hidden;
	white-space: nowrap;
	transition: background-color 0.3s ease-out;
	transform: translateY(-50%);

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: 3px;
		background-color: transparent;
		border: 2px solid #fff;
		border-radius: 100%;
	}

	&::after {
		content: '▶︎';
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		color: #fff;
		font-size: 32px;
		text-align: center;
		text-indent: 0;
		transform: translateY(-55%) translateX(3.5%);
	}
}
