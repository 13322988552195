.button {
	display: inline-block;
	padding: 6px 12px;
	color: #c0c0c0;
	font-size: 14px;
	font-weight: normal;
	line-height: 1;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	background: #333;
	border: 1px solid transparent;
	border-radius: 4px;
	cursor: pointer;
	transition: background 0.3s, color 0.3s;
	appearance: none;
}

.button:hover {
	color: #fff;
	text-decoration: none;
	transition: background 0.3s, color 0.3s;
}

.button--small {
	padding: 5px 10px;
	font-size: 12px;
	text-transform: none;
}

.button--large {
	padding: 12px 24px;
	font-size: 16px;
}

.button--block {
	display: block;
	width: 100%;
	margin: 0 0 5px;
}

.button--line {
	color: #7e7e7e;
	background: #fff;
	border-color: #c8c8c8;
}

.button--line:hover {
	color: #7e7e7e;
	background: #eee;
}

.button--text {
	color: #333;
	text-transform: none;
	background: none;
	border: none;
}

.button--text:hover {
	color: #333;
	text-decoration: underline;
}

.button--quickview {
	padding: 6px 12px;
	color: #fff;
	font-size: 1em;
	font-weight: bold;
	line-height: 1;
	text-transform: none;
	background: #333;
	border-radius: 3px;
}

.button--search {
	display: block;
	width: 14px;
	height: 14px;
	background: url('/images/search.png') no-repeat 0 0;
	background-size: 14px 14px;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.button--download::before {
	content: '\2193\00a0\00a0';
	color: inherit;
	font-weight: bold;
}

.link--pdf {
	display: inline-block;
	position: relative;
	padding-left: 37px;
}

.link--pdf::before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	width: 32px;
	height: 32px;
	background: url('/images/pdf_download_32x32.png') no-repeat 0 0;
	white-space: nowrap;
	overflow: hidden;
}

.button--hidden {
	display: none;
}

.button--disabled {
	opacity: 0.4;
	cursor: default;
}

.button--pending {
	position: relative;
	pointer-events: none;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 200;
		display: block;
		width: 1em;
		height: 1em;
		margin: auto;
		font-size: 3px;
		border-radius: 100%;
		animation: circle 1s infinite ease;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 100;
		background-color: rgba(0, 0, 0, 0.5);
	}
}

.button--small.button--pending {
	&::before {
		font-size: 2px;
	}
}

.button--large.button--pending {
	&::before {
		font-size: 5px;
	}
}

.no-cssanimations {
	.button--pending {
		opacity: 0.4;

		&::before, &::after {
			display: none;
		}
	}
}

@media only screen and (max-width: 480px) {
	.button--notify {
		padding: 5px 0;
	}
}

