﻿$promo-color: #58595b;
$promo-background-color: #f5f5f5;

.page--explore-deals-default {
    .main-content {
        max-width: none;
    }

    .breadcrumb {
        display: none;
    }
}

.explore-deals-wrap > div {
    max-width: 1000px;
    margin: 60px auto 0;
}

.explore-deals-wrap {
    p {
        font-size: 1.1em;
    }

    h1.bar,
    .promo-header p {
        padding-bottom: 10px;
    }

    h1.bar,
    h2.bar {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 0;
    }

    .bar span:before {
        content: none;
    }    

    .product-list-content {
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .product-list__footer {
        text-align: center;
    }

    .home-article__text .home-article__link:after {
        content: none;
    }

    .promo-banners--threecol {
        max-width: 1500px;
        margin-top: 0px;
    }

    .promo-header {
        max-width: none;
        margin: 0px auto;
    }
}

.promo-header {
    width: 100%;
    background-color: #F8F5F0;
    min-height: 150px;
    padding: 20px;
    text-align: center;
}

.promo-brands-wrap {
    margin-bottom: 20px;
    text-align: center;
}

.promo-brands {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.promo-brand {
    margin-top: 20px;
    width: 33.333%;
    display: flex;
}

.promo-brand__image {
    max-width: 70%;
}

.promo-brand__text {
    padding-top: 0px;
    font-size: 16px;
    color: $promo-color;
}

.promo-brand__link {
    &:hover {
        color: inherit;
    }
}

@media only screen and (min-width: 680.02px) {
    .promo-brand {
        width: 16.666%;
    }

    .promo-brand__text {
        padding-top: 0;
        font-size: 18px;
    }

    .promo-banner {
        padding-top: 25px;
    }

    .explore-deals-wrap {
        .product-list-content {
            margin-bottom: 50px;
        }

        & > div:last-of-type {
            padding-bottom: 20px;
        }
    }
}

.promo-banner:first-of-type {
    margin-top: 0px;
}

.promo-banner__content-wrap {
    background-color: $promo-background-color;
    padding: 15px 20px;

    a {
        font-weight: bold;
        text-transform: uppercase;
        color: inherit;
    }

    a::after {
        content: ">";
        padding-left: 5px;
    }
}

.promo-banner__content {    
    min-height: 100px;

    h3 {
        font-size: 18px;
    }
}

.product-list__header-link {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    color: inherit;

    &::after {
        content: ">";
        padding-left: 5px;
    }
}

.promo-banner__text,
.promo-grid__text {
    line-height: 1.4;
}

.promo-banner.md-12,
.promo-banner > .md-12 {
    margin-top: 15px;
    padding: 0 10px;
}

.promo-grid__content {
    padding: 30px;
}

.promo-assistance {    
    text-align: center;
    
    a {
        font-weight: bold;        
        color: inherit;
    }
}

.promo-assistance .g-6 {
    padding: 10px 0;
    border-bottom: 2px solid $promo-color;
}

.promo-assistance .g-6:first-of-type {
    border-top: 2px solid $promo-color;
    margin-top: 40px;
}

@media only screen and (min-width: 680.02px) {    
    .promo-assistance .g-6 {
        border-right: 2px solid $promo-color;
        border-top: 2px solid $promo-color;
        margin-top: 40px;
    }

    .promo-assistance .g-6:first-of-type {
        border-left: 2px solid $promo-color;
    }
}

// Resources

.promo-resources {
    .row {
        margin-top: 20px;
    }

    p {
        font-size: 15px;
        font-weight: 700;
    }
}

.promo-resources__flex {
    display: flex;
    align-items: center;
    background-color: $promo-background-color;
    margin-top: 20px;
}

.promo-resources__img {
    padding-left: 0px;
    max-width: 40%;
    margin-bottom: -4px;
}

.promo-resources__block {
    padding-left: 20px;

    & a:after {
        content: ">";
        padding-left: 5px;
    }
}

@media only screen and (min-width: 680.02px) {
    .promo-resources p {
        font-size: 13px;
    }
}

@media only screen and (min-width: 820px) {
    .promo-resources p {
        font-size: 17px;
    }
}