.samples {
	// display: flex;
	// flex-direction: row;
	// flex-wrap: wrap;
	margin: 30px 0;
}

.samples__toggle--container {
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;

	.samples__toggle--svg-container {
		display: flex;
		align-items: center;
		position: relative;
		z-index: 0;
		padding: 12px;

		svg {
			transition: all 0.2s ease;
			transform: rotate(180deg);
		}
	}
}

.samples__toggle {
	display: flex;
	align-items: center;
	/*z-index: 400;*/
	width: 100%;
	max-width: 400px;
	padding: 0 15px;
	padding-right: 0;
	color: #fff;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 1px;
	text-align: left;
	text-transform: uppercase;
	background: #1d1d1d;
	border: none;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
	cursor: pointer;

	/*removed 1/11/22 - was causing white line across page*/
	/*.samples--prod-visible &::after {
		content: "";
		position: absolute;
		right: 0;
		bottom: -5px;
		left: 0;
		width: 100%;
		height: 10px;
		background: #fefefe;
		pointer-events: none;
		opacity: 1;
	}*/

	p, img {
		margin: 0 6px;
	}

	p {
		margin-right: 15px;
	}
}

.samples--prod-visible {
    .samples__toggle--svg-container svg {
        transform: rotate(0deg);
    }
}

.samples__inner {
	display: none;
	position: relative;
	top: 1px;
	/*z-index: 300;*/
	max-width: none;
	padding: 0;
	// max-width: 750px;
	// padding: 10px;
	background: #fefefe;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
	overflow: hidden;

	.samples--prod-visible & {
		display: block;
	}
}

.sample {
	position: relative;
	padding: 15px;

	&::after {
		content: '';
		width: 70%;
		height: 1px;
		// background: #eee;
		background: linear-gradient(to right, rgba(238, 238, 238, 0) 0%, rgba(238, 238, 238, 1) 10%, rgba(238, 238, 238, 1) 90%, rgba(238, 238, 238, 0) 100%);
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		margin: 0 auto;
	}

	&:last-child::after {
		display: none;
	}
}

.sample__title {
	margin: 0;
	padding: 0;
	font-size: 18px;
	line-height: 1.25;
	color: #d32121;
}

.sample__description {
	margin: 5px 0 15px 0;
	padding: 0;
}

.sample__inner {
	overflow-x: hidden;
}

.sample__add-more {
	display: block;
	color: #d81f1f;
	font-style: italic;
	font-weight: bold;
}

.sample__auto-add-message {
	display: block;
	color: $green-lime;
	font-style: italic;
	font-weight: bold;
}


.sample__products {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -10px;
}

.sample-product {
	width: calc(100% / 6);
	padding: 5px 10px;
	text-align: center;

	.list-cart {
		text-align: center;
	}
}

.prod-info .sample-product {
	width: 20%
}

@media only screen and (max-width: 480px) {
	.sample-product,
	.prod-info .sample-product {
		width: 33.33%;
	}
}

.sample-product__image {
	display: block;
	max-width: 75px;
	height: auto;
	margin: 0 auto;
}

.sample-product__name {
	margin: 0 0 5px;
	padding: 0;
	font-size: 12px;
}