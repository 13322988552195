.site-logo__link {
	display: inline-block;
}

.site-logo__img {
	display: block;
	width: 100%;
	height: auto;
}

@media only screen and (min-width: 540px) {
	.site-logo {
		padding-right: 15px;
	}
}