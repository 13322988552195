.bxgy__section {
	margin: 15px 0;
	border: 1px solid #ddd;
	border-radius: 2px;
}

.bxgy__header {
	width: 100%;
	padding: 30px;
	font-size: 20px;
	text-align: center;
	background-color: #272727;
	color: #fff;
	border-bottom: 1px solid #ddd;
}

.bxgy-product {
	display: grid;
	width: 100%;
	text-align: center;
	vertical-align: top;
	padding: 15px 0;
	font-size: 11px;
	border-top: 1px solid #ddd;
	grid-template-columns: auto 1fr auto;
	grid-gap: 5px;

	.is--logged-out & {
		grid-template-columns: auto 1fr 70px;
	}
}

.bxgy-product:first-of-type {
	border-top: none;
}

.bxgy__price {
	text-align: center;
	width: 100%;
	margin: -10px 0 10px;
}

.bxgy__add, .bxgy__qty {
	margin: 10px;
	text-align: center;

	.button {
		border: 2px solid #fff;
	}
}

.bxgy__qty {
	span {
		font-weight: bold;
		padding-right: 2px;
	}
}

.bxgy-product__photo {
	grid-column: 1;
}

.bxgy-product__header {
	grid-column: 2;
	text-align: left;
}

.bxgy-product__title {
	margin-bottom: 5px;
	font-size: 12px;
	line-height: 1.4;
}

.bxgy-product__get-pricing,
.bxgy-product__pricing {
	grid-column: 3;
	padding-right: 5px;
}

.bxgy-product__pricing {
	display: grid;
	grid-gap: 5px;
	grid-template-columns: 1fr 50px;
	align-items: center;	
	min-height: 55px;
}

.bxgy-product__get-pricing {
	margin-top: 15px;
	text-align: center;
}

.bxgy-product__price, .bxgy-product__qty {
	text-align: center;
}

.bxgy-product__price-label {
	display: block;
	margin-bottom: 2px;
}

.bxgy-product__price-value {
	font-size: 18px;
	font-weight: bold;
}

.bxgy-product__no-stock {
	text-align: center;
}

.bxgy__quantities-container {
	bottom: 0px;
	position: sticky;
	opacity: 1;
	background: #272727;
	width: 100%;
	max-width: 1000px;
	z-index: 900;
	padding: 10px 15px;
	color: #fff;
	align-items: start;
	display: grid;
	font-size: 1.1em;
	grid-template-columns: auto;
	grid-column-gap: 5px;
}

.bxgy__quantities {
	display: grid;
	text-align: center;
	padding: 5px 0;
}

.bxgy-quantity {
	padding: 2px 0;
	text-align: center;
}

.bxgy-quantity--label {
	display: block;	
	text-decoration: underline;
	font-weight: 700;
	grid-row: 1;
	grid-column: 1 / span 2;
}

.bxgy-quantity--selected {
	grid-row: 2;
	grid-column: 1 / span 2;
}

.bxgy-quantity--required {
	font-weight: bold;
	grid-row: 3;
	grid-column: 1 / span 2;
}

.bxgy__add-to-cart {
	grid-row: 2;
	grid-column: 1 / span 2;
	margin: 0 auto;
	display: flex;
	align-items: center;
}

.bxgy__error-container {
	grid-row: 3;
	grid-column: 1 / span 2;
	text-align: center;
}

.bxgy__error.lbl--error,
.bxgy__error.lbl--success {
	display: block;
	margin: 5px 0;
}

@media only screen and (max-width: 480px) {
	.bxgy__quantities {
		text-align: left;
	}

	.bxgy-quantity {
		text-align: left;
	}
}

@media only screen and (min-width: 680.02px) {
	.bxgy__price {
		width: fit-content;
	}

	.bxgy {
		padding: 0 10px;
	}

	.bxgy__header {
		font-size: 24px;
	}

	.bxgy-product {
		display: inline-grid;
		grid-gap: 5px;
		width: 49%;
		border: none;
		padding: 20px 0;
	}
}

@media only screen and (min-width: 820px) {
	.bxgy__inner {
		padding: 0 10px;
	}

	.bxgy-product {
		grid-gap: 10px;
		padding: 20px 5px;
	}

	.bxgy__quantities-container {
		grid-template-columns: 1fr 1fr auto;
		align-items: center;
		padding: 5px 20px;

		.is--logged-out & {
			grid-template-columns: 1fr 1fr;
		}
	}

	.bxgy__add-to-cart {
		grid-row: 1;
		grid-column: 3;
	}

	.bxgy__error-container {
		grid-row: 2;
		grid-column: 1 / span 3;

		.is--logged-out & {
			grid-column: 1 / span 2;
		}
	}

	.bxgy-quantity {
		padding: 2px 5px;
	}

	.bxgy-quantity--selected {
		grid-column: 1;
		text-align: right;
		padding-right: 15px;
	}

	.bxgy-quantity--required {
		grid-row: 2;
		grid-column: 2;
		text-align: left;
		padding-left: 15px;
	}
}

@media only screen and (min-width: 920px) {
	/*.bxgy-product__pricing {
		grid-column: 3 / span 2;
		grid-gap: 10px;
		margin-left: 15px;
	}*/

}
