.myaccount-nav {
    margin: 10px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.myaccount-nav__link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 10px 25px 10px 30px; 
    border-radius: 25px;
    margin: 5px 10px;
    transition: 0.3s all;
    background-color: #bfbfbf;

    &--selected {
        background-color: #737373;
    }

    &:hover {
        text-decoration: none;
        background-color: #737373;
    }
}

.myaccount-nav__icon {
    display: none;
}

.myaccount-nav__text {
    text-align: center;
    width: 100%;
}

.myaccount-nav__name {
  margin: 0;
  padding: 0;
  //font-family: "Acumin Pro", "acumin-pro", sans-serif;
  font-weight: 600;
  color: #ffffff;
  font-size: 0.9em;
}

.myaccount-nav__description {
  margin: 5px 0 0;
  padding: 0;
}

@media only screen and (min-width: 620.02px) {
    .myaccount-nav {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (min-width: 820px) {
    .myaccount-nav {
        grid-template-columns: repeat(5, 1fr);
        margin: 20px 0;
    }

    .myaccount-nav__link {
        margin: 0px 10px;
    }

    .myaccount-nav__icon {
        display: block;
        text-align: center;

        img {
            width: 24px;
        }
    }

    .myaccount-nav__icon-qo img {
        width: 30px;
    }

    .myaccount-nav__text {
        flex: 1;
        align-self: center;
        margin-left: 10px;
        text-align: left;
    }
}
