.multi-select {
	position: relative;
	z-index: 200;
	display: inline-block;
	min-width: 200px;
	max-width: 300px;
}

.multi-select__toggle {
	position: relative;
	display: block;
	width: 100%;
	padding: 5px 10px;
	font-family: inherit;
	font-size: 1em;
	text-align: left;
	background: #fff;
	border: 1px solid $gray6;
	border-radius: 3px;
}

.multi-select__toggle::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 10px;
	width: 0;
	height: 0;
	margin: auto;
	display: block;
	border-top: 4px solid;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}

.multi-select__list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.multi-select__list > li {
	padding: 0.25em;
	line-height: 1;
}

.multi-select__options {
	display: none;
	position: absolute;
	width: 100%;
	max-height: 10.25em;
	overflow-y: auto;
	background: #fff;
	border: 1px solid #ddd;
	border-top: none;
	border-radius: 0 0 3px 3px;
}

.multi-select__overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 199;
	background-color: transparent;
}

.multi-select label {
	display: block;
}

.multi-select--hidden {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	height: 1px !important;
	margin: 1px 1px 1px 3px !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	left: 50%;
	top: 30px;
}

.register .multi-select {
    width: 100%;
    max-width: 100%;
}