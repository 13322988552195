.catalogs__header {
	margin: 0;
	padding: 10px;
	color: $gray1;
	font-size: 1.8em;
	font-weight: normal;
	text-transform: uppercase;
}

.catalog {
	margin-top: 15px;
	padding: 0 10px;
	border-bottom: 1px solid #efefef;
}

.catalog:first-child {
	margin-top: 0;
}

.catalog:last-child {
	border-bottom: 1px solid #ccc;
}

.catalog__link {
	display: block;
	overflow: hidden;
}

.catalog__link:hover {
	text-decoration: none;
}

.catalog__image {
	display: inline-block;
	margin-bottom: -12px;
}

.catalog__text {
	display: inline-block;
	padding: 8px;
	font-size: 1.2em;
	font-weight: bold;
}

.catalog__text::after {
	content: '';
	display: inline-block;
	margin: 0 0 -5px 5px;
	width: 24px;
	height: 24px;
	background-image: url('/images/arrow-blue.png');
	background-position: 0 0;
	background-repeat: no-repeat;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.catalog__link:hover .catalog__text {
	text-decoration: underline;
}

@media only screen and (min-width: 640px) {
	.catalogs__header {
		padding-bottom: 0;
	}

	.catalogs__list {
		display: flex;
		flex-wrap: wrap;
		align-content: flex-end;
	}

	.catalog {
		flex: 0 1 auto;
		width: 50%;
	}

	.catalog:first-child {
		margin-top: 15px;
	}

	.catalog:last-child {
		border-bottom: 1px solid #efefef;
	}
}


@media only screen and (min-width: 1000px) {
	.catalogs {
		display: flex;
		flex-direction: row;
		margin-top: 30px;
		border-top: 1px solid #ddd;
	}

	.catalogs__header {
		flex: 0 0 auto;
		align-self: flex-end;
		padding: 10px 20px 10px 10px;
		border-bottom: 1px solid #efefef;
	}

	.catalogs__list {
		flex: 1 1 auto;
	}

	.catalog {
		flex: 1 1 auto;
	}
}
