// .ig-widget__list {
// 	width: 500%;
// 	margin: 0;
// 	padding: 0;
// 	list-style: none;
// 	overflow: hidden;
// }

// .ig-widget__item {
// 	float: left;
// }

// .ig-widget__track {
// 	overflow: hidden;
// 	width: 320px;
// 	margin: 0 auto;
// }

// .ig-widget__image {
// 	display: block;
// 	width: 100%;
// 	height: auto;
// }

// @media only screen and (min-width: 520px) {
// 	.ig-widget__track {
// 		width: auto;
// 	}

// 	.ig-widget__list {
// 		width: auto;
// 		margin: 0 -2px;
// 	}

// 	.ig-widget__item {
// 		float: left;
// 		width: 33.3333333%;
// 		margin-bottom: 4px;
// 		padding: 0 2px;
// 	}
// }

// @media only screen and (min-width: 820px) {
// 	.ig-widget__item {
// 		width: 20%;
// 	}
// }

// .slider__outer {
// 	width: 320px;
// 	overflow: hidden;
// }

// .slider__inner {
// 	width: 15000px;
// 	transition: transform 1s cubic-bezier(0.71, 0.01, 0.49, 1.01);
// }

// .slider--resetting .slider__inner {
// 	transition: none;
// }

.slide {
	float: left;
	width: 320px;
	height: 320px;
}

.slide img {
	display: block;
	max-width: 100%;
	height: auto;
}

.js--insta-account {
	display: none;
}

.instagram-widget {
	max-width: 1000px;
	margin: 0 auto;
}

.instagram-widget__link {
	display: block;
	padding: 10px 0;
	font-size: 1.15em;
	font-weight: bold;
	text-align: right;
}

#instagram-widget .bx-controls-direction, #instagram-widget .bx-pager {
	display: none;
}

@media only screen and (min-width: 480px) {
	#instagram-widget {
		overflow: hidden;
		margin: 0 -2px;
	}

	.slide {
		width: 33.33333333%;
		height: auto;
		padding: 0 2px;
	}
}

@media only screen and (min-width: 820px) {
	.slide {
		width: 20%;
	}
}
