.accordion {
	margin-top: 10px;
}

.accordion__section {
	border-top: 1px solid #ddd;

	&:last-child {
		border-bottom: 1px solid #ddd;
	}
}

.accordion__toggle {
	display: block;
	position: relative;
	width: 100%;
	padding: 15px 10px;
	font-size: 16px;
	line-height: 1;
	text-align: left;
	background: none;
	border: none;
	cursor: pointer;
	color: #4c4c4c;
	
	&::after {
		content: '\25bc';
		position: absolute;
		top: 50%;
		right: 10px;
		font-size: 12px;
		line-height: 16px;
		transform: translateY(-50%);
	}

	.accordion__section--active &::after {
		content: '\25b2';
	}
}

.accordion__content {
	display: none;
	padding: 15px 10px;

	.accordion__section--active & {
		display: block;
	}
}
