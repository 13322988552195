.autocomplete-suggestions {
	display: none;
	position: absolute;
	z-index: 9999;
	width: 85% !important;
	max-width: 350px;
	max-height: 254px;
	box-sizing: border-box;
	overflow: hidden;
	overflow-y: auto;
}

.autocomplete-suggestions {
	text-align: left;
	cursor: default;
	border: 1px solid #ccc;
	background: #fff;
	box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.1);
}

.autocomplete-suggestion {
	position: relative;
	padding: 0.8em 0.6em;
	color: #333;
	font-size: 1.02em;
	line-height: 1.25;
	border-top: 1px solid #ddd;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.autocomplete-suggestion:first-child {
	border-top: none;
}

.autocomplete-suggestion b {
	color: #1f8dd6;
	font-weight: normal;
}

.autocomplete-suggestion.selected {
	background: #f0f0f0;
}

.autocomplete-prod-info {
	margin-top: 5px;
	font-size: 0.8em;
	overflow: hidden;
}

.autocomplete-sku {
	float: left;
}

.autocomplete-size {
	float: right;
}
