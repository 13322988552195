.error-404 {
	max-width: 500px;
	margin: 60px auto;
	font-size: 18px;
	text-align: center;
}

.error-404__title {
	font-size: 48px;
}

.error-404__url {
	display: inline-block;
	background-color: #eee;
	padding: 2px 8px;
	border-radius: 2px;
}

.error-404__links {
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-content: start;
	font-size: 16px;
}

.error-404__link {
	display: inline-block;
	padding: 0 8px;
	text-align: left;

	&:nth-child(odd) {
		justify-self: end;
		border-right: 1px solid #aaa;
	}

	&:nth-child(even) {
		padding-left: 9px;
	}
}
