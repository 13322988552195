.contact__form {
	padding: 15px 0 0;
}

.contact__item {
	margin-bottom: 15px;
}

.contact__status {
	display: block;
	padding: 15px 0 5px 0;
	font-size: 1.15em;
}

.RadCaptcha {
	margin: 0 0 5px;
}
