.custom-select {
	display: block;
	position: relative;
	font-size: 16px; // Prevents iOS page zoom on focus
	border: 1px solid #ddd;
	border-radius: 3px;
}

.custom-select select {
	width: 100%;
	margin: 0;
	padding: 0.6em 0.8em 0.5em 0.8em;
	font-size: inherit;
	box-sizing: border-box;
}

.custom-select::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 0.5em;
	z-index: 2;
	// Make the arrow clickable in some browsers
	display: none;
	pointer-events: none;
}

.custom-select::after {
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 7px solid $gray6;
	margin-top: -3px;
}

@supports ( -webkit-appearance: none ) or ( appearance: none ) {
	.custom-select::after {
		display: block; // show the custom arrow
	}

	.custom-select select {
		padding-right: 2em;
		background: none; // Inside @supports so iOS <= 8 displays the native arrow
		border: 1px solid transparent; // Inside @supports so Android <= 4.3 displays the native arrow
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.custom-select select:focus {
		border-color: #ddd;
	}
}

// This block is duplicated because something in the build process was being dumb.
@supports ( ( -moz-appearance: none ) and ( mask-type: alpha ) ) {
	// Firefox <= 34 has a false positive on @supports (-moz-appearance: none)
	// @supports (mask-type: alpha) is for Firefox >= 35

	.custom-select::after {
		display: block; // show the custom arrow
	}

	.custom-select select {
		padding-right: 2em;
		background: none; // Inside @supports so iOS <= 8 displays the native arrow
		border: 1px solid transparent; // Inside @supports so Android <= 4.3 displays the native arrow
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.custom-select select:focus {
		border-color: #ddd;
	}
}