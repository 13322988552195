.coupon {
	margin: 0 0 10px;
}

.coupon__fields {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
}

.coupon__button {
	margin-left: 10px;
}
