.account-header__nav {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 9000;
	width: 260px;
	margin-left: 260px;
	padding-top: 2em;
	background-color: #000;
	overflow-x: hidden;
	overflow-y: auto;
	transform: translateX(260px);
	transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);

	&.is--active {
		transform: translateX(0);
	}
}

.account-header__list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.account-header__item {
	padding: 10px 10px 10px 0;
	margin: 0 10px;
	border-top-color: #fff;
	border-top-style: solid;
	border-top-width: 1px;

	&:first-child {
		border-top-color: transparent;
	}
}

.account-header__link {
	color: inherit;
	text-transform: uppercase;
}

.account-header__close {
	position: absolute;
	top: 0;
	right: 5px;
	padding: 0.25em 0.5em;
	color: #fff;
	font-size: 1.25em;
	font-weight: 700;
	line-height: 1;
	background: none;
	border: none;
}

.account-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 100%;
	left: 0;
	z-index: 8000;
	background: $black;
	opacity: 0;
	transition: bottom 0.1s 0.3s, opacity 0.3s ease-in; // fade out

	&.is--active {
		bottom: 0;
		opacity: 0.6;
		transition: bottom 0.1s, opacity 0.3s 0.1s ease-in; // fade in
	}
}

@media only screen and (min-width: 820px) {
	.account-header__close {
		display: none;
	}

	.account-header__nav {
		display: none;
		position: absolute;
		top: 100%;
		right: 0;
		z-index: 8100;
		bottom: auto;
		padding-top: 0;
		transform: none;

		&.is--active {
			display: block;
			transform: none;
		}
	}

	.account-overlay {
		background-color: rgba(255, 255, 255, 0);
	}
}
