.component-image {
	position: relative;
}

.component-image__zoom {
	position: absolute;
	top: 15px;
	right: 15px;
	display: none;
	width: 24px;
	height: 24px;
	line-height: 1;
	background: none;
	background-image: url('/images/search.png');
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 24px 24px;
	border: none;
	font-size: 0;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	opacity: 0;
	transition: opacity 0.3s;

	.component-image:hover & {
		opacity: 1;
	}
}
