.standard-banners {}

.standard-banner {
	max-width: 1400px;
	margin: 30px auto;
}

.standard-banner__link {
	display: block;
}

.standard-banner__image {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 0 auto;
}
