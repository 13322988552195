.verify-billing {
	max-width: 400px;
	margin: 45px 0 30px;
}

.verify-billing__header {
	font-size: 1.25em;
}

.verify-billing__row {}

.verify-billing__fields {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -5px;
	overflow: hidden;
}

.verify-billing__field {
	flex: 10 10 100%;
	padding: 5px;
}

@media only screen and (min-width: 460px) {
	.verify-billing__field--city {
		flex: 5 5 50%;
	}

	.verify-billing__field--state {
		flex: 2 2 20%;
	}

	.verify-billing__field--zip {
		flex: 3 3 30%;
	}
}
