.lookup-wrap {
	padding: 0 10px;
	margin-bottom: 30px;
	overflow: auto;
}

.lookup-legend {
	font-weight: normal;
}

.lookup-filters {
	margin: 0 auto 15px;
}

.lookup-wrap label {
	display: block;
	margin: 0 0 2px;
}

.lookup-wrap .RadComboBox, .lookup-wrap .RadPicker {
	margin: 0 0 5px;
}

.lookup-filter-label {
	display: inline-block;
	min-width: 85px;
	text-align: right;	
}

.order-detail-wrap {
	max-height: 71.5vh;
	padding: 10px;
	font-size: 0.85em;
	overflow-y: scroll;
}

.order-detail-wrap h3 {
	margin: 0 0 0.25em;
	border-bottom: 1px solid $gray6;
}

.order-detail-wrap .row {
	padding-bottom: 15px;
}

.detail-tbl tfoot td {
	padding-top: 2px;
	padding-bottom: 2px;
	text-align: right;
}

.rgEditForm .rgHeader {
	height: auto !important;
	padding-bottom: 8px;
	overflow: hidden;
}

.button-search {
	display: block;
	width: 14px;
	height: 14px;
	background-image: url('/images/search-14.png');
	background-position: 0 0;
	background-repeat: no-repeat;
	white-space: nowrap;
	text-indent: 100%;
	overflow: hidden;
}

.button-save {
	display: block;
	width: 24px;
	height: 24px;

	img {
		width: 24px;
	}
}

.account-table {
	width: 100%;
	font-size: 12px;
	text-align: left;
	border: 1px solid #ddd;
	border-collapse: collapse;
	border-radius: 3px;

	thead {
		th {
			padding: 5px;
			line-height: 1;
			background-color: #ededed;
			white-space: nowrap;
		}
	}

	tbody,
	tfoot {
		td {
			padding: 5px;
			border-top: 1px solid #ddd;
		}
	}

	.button-search {
		margin: 0 auto;
	}

	.button-save {
		margin: 0 auto;

		img {
			display: block;
			max-width: 100%;
			height: auto;
			margin: 0 auto;
		}
	}
}

.account-table__track a {
	color: inherit;
	text-decoration: underline;
}

.account-table--currency {
	text-align: right;
}

.account-table--center {
	text-align: center;
}

.order-history-table {
	.rgMasterTable {
		font-size: 11px !important;
		line-height: 1.25 !important;
	}

	.rgEditForm {
		z-index: 9000 !important;
	}

	.icon-pdf {
		display: inline-block;
		max-width: 24px;
		height: auto;
	}


	.account-table {
		font-size: 11px;

		thead th {
			white-space: normal;
		}
	}

	.RadGrid_Silk {
		border: none;

		.rgHeader {
			font-weight: bold;
			text-align: left !important;
		}
	}
}

.order-history__track a {
	color: inherit;
	text-decoration: underline;
}

.order-history__no-items {
	padding: 0 10px;
}

.bc-orders__header {
	margin: 0;
	padding: 0 0 5px;
	font-size: 16px;
}

.bc-orders__section {
	margin-bottom: 20px;
}

@media only screen and (min-width: 840px) {
	.bc-orders {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
	}

	.bc-orders__section {
		flex: 1 1 50%;
		padding-left: 10px;

		&:first-child {
			padding-right: 10px;
			padding-left: 0;
		}
	}

	.bc-orders__header {
		display: none;
	}
}

.bc-orders .selected td {
	font-weight: bold;
	background-color: #ffffe0;
}
