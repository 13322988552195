.product-card {
	display: flex;
	flex-direction: column;
}

.product-card__link {
	display: flex;
	flex-direction: column;
	color: inherit;
}

.product-card__link:hover {
	text-decoration: none;
}

.product-card__photo {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 0 auto 5px;
}

.product-card__name {
	margin: 0;
	padding: 0;
	font-size: 1em;
}

.product-card__size, .product-card__sku {
	margin: 0;
	padding: 0;
}

.product-card__sku {
	display: flex;
	flex: 1 0 auto;
	align-items: flex-end;
}

.prod-list__item {
	display: flex;
	flex-direction: column;
}
