﻿.google-translate {
    margin: 0 auto;
}

.site-footer {
    .google-translate {
        display: none;    
    }
}

@media only screen and (min-width: 820px) {
    .site-header {
        .google-translate {
            padding-top: 40px;

            .is--logged-in & {
                padding-top: 60px;
            }
        }
    }

    .translated-ltr .top-header,
    .translated-rtl .top-header {
        padding-top: 40px;
    }
}

@media only screen and (max-width: 620px) {
    .site-header {
        .google-translate {
            display: none;
        }
    }

    .site-footer {
        .google-translate {     
            display: block;
            text-align: center;
        }
    }
}