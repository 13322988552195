.row, .row-padded, .row-nopad {
	position: relative;
	clear: both;
	margin: 0;
	*zoom: 1;

	&::before, &::after {
		content: '';
		display: table;
	}

	&::after {
		clear: both;
	}
}

.row .row {
	margin-right: -10px;
	margin-left: -10px;
	padding-left: 0;
	padding-right: 0;
}

.row-padded {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.g-1, .md-1, .sm-1, .g-2, .md-2, .sm-2, .g-3, .md-3, .sm-3, .g-4, .md-4, .sm-4, .g-5, .md-5, .sm-5, .g-6, .md-6, .sm-6, .g-7, .md-7, .sm-7, .g-8, .md-8, .sm-8, .g-9, .md-9, .sm-9, .g-10, .md-10, .sm-10, .g-11, .md-11, .sm-11, .g-12, .md-12, .sm-12, .g-quarter, .md-quarter, .sm-quarter, .g-third, .md-third, .sm-third, .g-half, .md-half, .sm-half, .g-full, .md-full, .sm-full {
	position: relative;
	float: left;
	padding: 0 10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.row-nopad > .g-1, .row-nopad > .md-1, .row-nopad > .sm-1, .row-nopad > .g-2, .row-nopad > .md-2, .row-nopad > .sm-2, .row-nopad > .g-3, .row-nopad > .md-3, .row-nopad > .sm-3, .row-nopad > .g-4, .row-nopad > .md-4, .row-nopad > .sm-4, .row-nopad > .g-5, .row-nopad > .md-5, .row-nopad > .sm-5, .row-nopad > .g-6, .row-nopad > .md-6, .row-nopad > .sm-6, .row-nopad > .g-7, .row-nopad > .md-7, .row-nopad > .sm-7, .row-nopad > .g-8, .row-nopad > .md-8, .row-nopad > .sm-8, .row-nopad > .g-9, .row-nopad > .md-9, .row-nopad > .sm-9, .row-nopad > .g-10, .row-nopad > .md-10, .row-nopad > .sm-10, .row-nopad > .g-11, .row-nopad > .md-11, .row-nopad > .sm-11, .row-nopad > .g-12, .row-nopad > .md-12, .row-nopad > .sm-12 {
	padding-left: 0;
	padding-right: 0;
}

.g-right {
	float: right;
}

.g-1 {
	width: 8.33333%;
}

.push-1 {
	left: 8.33333%;
}

.pull-1 {
	right: 8.33333%;
}

.g-2 {
	width: 16.66667%;
}

.push-2 {
	left: 16.66667%;
}

.pull-2 {
	right: 16.66667%;
}

.g-3 {
	width: 25%;
}

.push-3 {
	left: 25%;
}

.pull-3 {
	right: 25%;
}

.g-4 {
	width: 33.33333%;
}

.push-4 {
	left: 33.33333%;
}

.pull-4 {
	right: 33.33333%;
}

.g-5 {
	width: 41.66667%;
}

.push-5 {
	left: 41.66667%;
}

.pull-5 {
	right: 41.66667%;
}

.g-6 {
	width: 50%;
}

.push-6 {
	left: 50%;
}

.pull-6 {
	right: 50%;
}

.g-7 {
	width: 58.33333%;
}

.push-7 {
	left: 58.33333%;
}

.pull-7 {
	right: 58.33333%;
}

.g-8 {
	width: 66.66667%;
}

.push-8 {
	left: 66.66667%;
}

.pull-8 {
	right: 66.66667%;
}

.g-9 {
	width: 75%;
}

.push-9 {
	left: 75%;
}

.pull-9 {
	right: 75%;
}

.g-10 {
	width: 83.33333%;
}

.push-10 {
	left: 83.33333%;
}

.pull-10 {
	right: 83.33333%;
}

.g-11 {
	width: 91.66667%;
}

.push-11 {
	left: 91.66667%;
}

.pull-11 {
	right: 91.66667%;
}

.g-12 {
	width: 100%;
}

.push-12 {
	left: 100%;
}

.pull-12 {
	right: 100%;
}

.g-hide {
	display: none;
}

.g-show {
	display: block;
}

.g-quarter {
	width: 25%;
}

.g-third {
	width: 33.33333%;
}

.g-half {
	width: 50%;
}

.g-full {
	width: 100%;
}

.g-clear {
    clear: left;
}

@media only screen and (max-width: 680px) {
	.md-1 {
		width: 8.33333%;
	}

	.push-1 {
		left: 0;
	}

	.pull-1 {
		right: 0;
	}

	.md-2 {
		width: 16.66667%;
	}

	.push-2 {
		left: 0;
	}

	.pull-2 {
		right: 0;
	}

	.md-3 {
		width: 25%;
	}

	.push-3 {
		left: 0;
	}

	.pull-3 {
		right: 0;
	}

	.md-4 {
		width: 33.33333%;
	}

	.push-4 {
		left: 0;
	}

	.pull-4 {
		right: 0;
	}

	.md-5 {
		width: 41.66667%;
	}

	.push-5 {
		left: 0;
	}

	.pull-5 {
		right: 0;
	}

	.md-6 {
		width: 50%;
	}

	.push-6 {
		left: 0;
	}

	.pull-6 {
		right: 0;
	}

	.md-7 {
		width: 58.33333%;
	}

	.push-7 {
		left: 0;
	}

	.pull-7 {
		right: 0;
	}

	.md-8 {
		width: 66.66667%;
	}

	.push-8 {
		left: 0;
	}

	.pull-8 {
		right: 0;
	}

	.md-9 {
		width: 75%;
	}

	.push-9 {
		left: 0;
	}

	.pull-9 {
		right: 0;
	}

	.md-10 {
		width: 83.33333%;
	}

	.push-10 {
		left: 0;
	}

	.pull-10 {
		right: 0;
	}

	.md-11 {
		width: 91.66667%;
	}

	.push-11 {
		left: 0;
	}

	.pull-11 {
		right: 0;
	}

	.md-12 {
		width: 100%;
	}

	.push-12 {
		left: 0;
	}

	.pull-12 {
		right: 0;
	}

	.md-quarter {
		width: 25%;
	}

	.md-third {
		width: 33.33333%;
	}

	.md-half {
		width: 50%;
	}

	.md-full {
		width: 100%;
	}

	.md-hide {
		display: none;
	}

	.md-show {
		display: block;
	}

	.row .row {
		margin-right: -10px;
		margin-left: -10px;
	}

    .g-clear {
        clear: none;
    }

    .md-clear {
        clear: both;
    }
}

@media only screen and (max-width: 480px) {
	.sm-1 {
		width: 8.33333%;
	}

	.sm-2 {
		width: 16.66667%;
	}

	.sm-3 {
		width: 25%;
	}

	.sm-4 {
		width: 33.33333%;
	}

	.sm-5 {
		width: 41.66667%;
	}

	.sm-6 {
		width: 50%;
	}

	.sm-7 {
		width: 58.33333%;
	}

	.sm-8 {
		width: 66.66667%;
	}

	.sm-9 {
		width: 75%;
	}

	.sm-10 {
		width: 83.33333%;
	}

	.sm-11 {
		width: 91.66667%;
	}

	.sm-12 {
		width: 100%;
	}

	.sm-quarter {
		width: 25%;
	}

	.sm-third {
		width: 33.33333%;
	}

	.sm-half {
		width: 50%;
	}

	.sm-full {
		width: 100%;
	}

	.sm-hide {
		display: none;
	}

	.sm-show {
		display: block;
	}

    .g-clear, .md-clear {
        clear: none;
    }

    .sm-clear {
        clear: both;
    }
}
