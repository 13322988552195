.customer-pickup-container {
  display: grid;
  display: -ms-grid;
  justify-content: center;
  align-items: center;

  label {
    grid-column: 1;
    -ms-grid-column: 1;
  }

  p {
    grid-column: 1;
    -ms-grid-column: 1;
    grid-row: 2;
    -ms-grid-row: 2;
  }

  a {
    grid-column: 2;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    height: 28px;
    grid-row: 1 / span 2;
  }
}

.AdditionalContact {
  display: flex;
  align-items: center;
}

@media (max-width: 480px) {
  .customer-pickup-container a {
    grid-column: 1;
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    grid-row: 3;
    margin-top: 10px;
    justify-self: baseline;
    margin-left: 0 !important;
  }

  .AdditionalContact {
    display: grid;
    grid-gap: 10px;
  }

  .AdditionalContact .coupon__button {
    margin-left: 0;
    justify-self: baseline;
  }
}

.preorder-warning {
    text-align: center;
    font-weight: bold;
    padding: 10px;
    background-color: yellow;
}