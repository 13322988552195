.list-view, .grid-view {
	flex: 1 0 auto;
	display: inline-block;
	padding: 3px 5px 3px 0;
	color: #333;
	text-align: center;
	opacity: 0.6;
	transition: opacity 0.3s;

	&:hover {
		text-decoration: none;
		opacity: 1;
		transition: opacity 0.3s;
	}
}

.list-view__icon, .grid-view__icon {
	display: inline-block;
	width: 24px;
	height: 24px;
	background-image: url('/images/sprite.png');
	background-position: 0 0;
	background-repeat: no-repeat;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
}

.list-view__label, .grid-view__label {
	display: block;
	color: inherit;
	font-size: 11px;
	line-height: 0.7;
	text-transform: uppercase;
}

.grid-view__icon {
	background-position: -24px 0;
}
