
.gdpr-notice {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 9000;
	width: 100%;
	color: #fff;
	font-size: 12px;
	background: #474747;
	transform: translateY(-100%);
	transition: transform 0.5s;
}

.gdpr-notice__inner {
	position: relative;
	max-width: 1000px;
	margin: 0 auto;
	padding: 15px 45px 15px 15px;
}

@media only screen and (min-width: 681px) {
	.gdpr-notice {
		position: fixed;
		font-size: 14px;
	}
}

.gdpr-notice__close {
	position: absolute;
	top: 15px;
	right: 15px;
	width: 25px;
	height: 25px;
	padding: 0 0 4px;
	color: #333;
	font-size: 14px;
	text-align: center;
	vertical-align: middle;
	background: #efefef;
	border: 1px solid #fff;
	border-radius: 100%;
	transition: background 0.3s;
	cursor: pointer;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}
// .gdpr-notice__close {
// 	display: inline-block;
// 	height: auto;
// 	margin-top: 15px;
// 	padding: 6px 24px;
// 	color: #333;
// 	line-height: 1;
// 	background: #efefef;
// 	border: 1px solid #fff;
// 	border-radius: 2px;
// 	cursor: pointer;
// 	transition: background 0.3s;
// }

.gdpr-notice__close:hover {
	background: #cecece;
}

.gdpr-notice p {
	margin: 0;
	padding: 0;
	line-height: 1.5;
}

.gdpr-notice__link {
	color: inherit;
	text-decoration: underline;
	white-space: nowrap;
}


.gdpr-notice--active .gdpr-notice {
	transform: translateY(0);
}

.is--gdpr .container {
	transition: padding-top 0.5s;
}

// .container {
// 	padding-top: 64px;
// }