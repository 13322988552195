.search {
	clear: both;
	display: flex;
	background: $white;
	border: 1px solid $gray6;
	border-radius: 3px 0 0 3px;
}

.search__options {
	flex: 0 0 95px;
	font-size: 14px;
	background: $gray8;
	border: none;
	border-radius: 0;
}

.search__text {
	position: relative;
	display: flex;
	flex: 9 0;
	border-left: 1px solid $gray6;
}

.search__field {
	display: block;
	width: 100%;
	padding: 5px;
	border: none;
}

.search__submit {
	flex: 0 0 14px;
	align-self: center;
	margin: 0 5px;
	border: none;
	cursor: pointer;
}

.search__options > select {
	padding: 0.2em 2em 0.3em 0.3em;
}

@media only screen and (max-width: 480px) {
	.search__text input {
		font-size: 16px;
	}
}
