.email-signup {
	display: none;
	position: fixed;
	top: 50%;
	right: 0;
	left: 0;
	z-index: 8000;
	width: 90%;
	max-width: 700px;
	margin: 0 auto;
	padding: 15px;
	color: #959595;
	background-color: #fff;
	box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
	transform: translateY(-50%);
}

.email-signup__close {
	position: absolute;
	top: 10px;
	right: 10px;
	text-decoration: none;
	text-transform: uppercase;
}

.email-signup__title {
	margin: 0 0 0.5em;
	padding: 0;
	font-size: 1.15em;
	font-weight: normal;
	text-transform: uppercase;
}

.email-signup label {
	display: block;
	margin-bottom: 3px;
	font-weight: bold;
}