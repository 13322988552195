.qty-break__button {
	display: inline-block;
	padding: 0;
	border: none;
	background: none;
	color: $blue;
	font-family: inherit;

	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

.qty-message {
	min-width: 200px;
	color: #fff;
	text-align: center;

	p {
		margin: 0;
		padding: 0 0 10px;
	}
}

.qty-table {
	min-width: 200px;
	font-size: 11px;
	border: 1px solid #eee;
	border-collapse: collapse;
}

.qty-table__header {
	padding: 5px 10px;
	text-align: center;
	background: #eee;
	border: 1px solid #ddd;
}

.qty-table__cell {
	padding: 5px 10px;
	text-align: center;
	border: 1px solid #ddd;
	background: #fff;
}

.pricing-notes {
	margin: 10px 0;
}

.pricing-notes__breaks {
	position: relative;
}

.pricing-notes__tooltip {
	display: none;
	position: absolute;
	z-index: 1000;
	padding: 25px 10px 10px;
	background: rgba(0, 0, 0, 0.8);
	border-radius: 3px;
	transform: translate(-25%, -100%);

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 0;
		height: 0;
		margin-left: -10px;
		margin-bottom: -20px;
		border: 20px solid transparent;
		border-top-color: rgba(0, 0, 0, 0.8);
		border-bottom: 0;
		border-right: 0;
	}
}

.pricing-notes__tooltip-close {
	display: inline-block;
	position: absolute;
	top: 0;
	right: 0;
	padding: 5px 10px;
	background: none;
	border: none;
	color: #fff;
	line-height: 1;
	cursor: pointer;
}

@media only screen and (min-width: 420px) {
	.pricing-notes__tooltip {
		transform: translate(-65%, -100%);
	}
}

// .speech-bubble { position: relative; background: #400040; border-radius: .4em; } .speech-bubble:after { content: ''; position: absolute; bottom: 0; left: 50%; width: 0; height: 0; border: 20px solid transparent; border-top-color: #400040; border-bottom: 0; border-right: 0; margin-left: -10px; margin-bottom: -20px; }