@media only screen and (min-width: 680px) {
	.account {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
	}

	.account__sidebar {
		flex: 1;
		padding: 10px;
	}

	.account__main {
		flex: 3;
		padding: 10px;
	}
}
